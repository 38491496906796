
import Vue from "vue";
import Login from "~/components/form/Login.vue";

export default Vue.extend({
  data() {
    return {
      title: "Login | Bikeflights.com",
    };
  },
  head() {
    return {
      title: this.title,
    };
  },
  middleware: "auth",
  auth: "guest",
  name: "login",
  components: { Login },
  methods: {
    redirectAfterLogin() {
      this.$router.push("/profile");
    },
  },
});
