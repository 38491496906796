var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form',{attrs:{"autocomplete":"on"},on:{"submit":function($event){$event.preventDefault();return _vm.submitLogin.apply(null, arguments)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.actionNotice),expression:"actionNotice"}],staticClass:"items-center"},[_c('Notice',{attrs:{"notice":_vm.actionNotice}})],1),_vm._v(" "),_c('div',{staticClass:"input-group mb-2",class:{
      'bf-form-error-col':
        (_vm.submitted && _vm.email && !_vm.isValidEmail()) ||
        _vm.authError ||
        (_vm.submitted && !_vm.email),
    }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],ref:"loginEmail",staticClass:"w-full text-base sm:text-sm",attrs:{"type":"email","maxlength":"50"},domProps:{"value":(_vm.email)},on:{"input":[function($event){if($event.target.composing)return;_vm.email=$event.target.value},_vm.resetVal]}}),_vm._v(" "),_c('label',[_vm._v("Email")])]),_vm._v(" "),_c('div',{staticClass:"input-group mb-2",class:{ 'bf-form-error-col': (_vm.submitted && !_vm.password) || _vm.authError }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"w-full text-base sm:text-sm",attrs:{"type":"password","maxlength":"50"},domProps:{"value":(_vm.password)},on:{"input":[function($event){if($event.target.composing)return;_vm.password=$event.target.value},_vm.resetVal]}}),_vm._v(" "),_c('label',[_vm._v("Password")])]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"text-center"},[_c('nuxtLink',{staticClass:"text-sm font-normal",attrs:{"target":"_self","to":"/forgotPassword"}},[_vm._v("Forgot Password?")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-2"},[_c('button',{staticClass:"uppercase w-full bg-brand-500 hover:bg-brand-400 text-white rounded font-semibold text-sm p-3 border-2 border-brand-500 hover:border-brand-400",attrs:{"aria-label":"login","type":"submit"}},[_vm._v("\n      Log In\n    ")])])
}]

export { render, staticRenderFns }